@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'poppins';
  src: url('assets/Poppins-Medium.otf');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: 'poppins', 'Helvetica Neue', sans-serif;
}
